import React, { useEffect } from 'react';

// ---------------------------------------------------------

import Container from '@layout/container';
import Layout from '@layout/index';

// ---------------------------------------------------------

const NotFoundPage = () => {
  useEffect(() => {
    if (window.Rollbar && process.env.NEXT_PUBLIC_NOTIFY_ROLLBAR_ON_404) {
      window.Rollbar.warning('404 Page Not Found');
    }
  }, []);

  return (
    <Layout>
      <Container spacing="large" gridOptions={{ layout: 'single' }}>
        <h1>NOT FOUND</h1>
        <p>Uh oh! This page doesn't exist.</p>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;
